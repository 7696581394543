<template>
	<!-- Navbar start -->
	<nav class="w-screen fixed  bg-white p-4 lg:px-20 flex justify-between z-50">
		<!-- Logo start -->
		<div class="h-full flex flex-col justify-center">
			<a href="/" role="menuitem">
				<img src="@/assets/logo.png" alt="" style="width: 8rem;">
			</a>
		</div>
		<!-- Logo end -->

		<!-- Toggler start -->
		<button role="menubar" class="md:hidden" @click="navToggle()">
			<i class="fa-solid fa-bars text-2xl"></i>
		</button>
		<!-- Toggler end -->

		<!-- Menu content start -->
		<div class="title-text absolute left-0 right-0 translate-y-16 bg-opacity-90 bg-white md:bg-none md:bg-opacity-0 shadow hidden md:flex flex-col gap-4 items-center p-4 md:flex-row md:static md:shadow-none md:translate-y-0"
			role="menu" aria-expanded="false">
			<!-- Links start -->
			<a href="/" role="menuitem">
				<p class="mx-4">Home</p>
			</a>

			<a href="/work" role="menuitem">
				<p class="mx-4">Work</p>
			</a>

			<a href="/press" role="menuitem">
				<p class="mx-4">Prensa</p>
			</a>

			<a href="/about" role="menuitem">
				<p class="mx-4">About</p>
			</a>

			<a href="/contact" role="menuitem">
				<p class="mx-4">Contacto</p>
			</a>
			<!-- Links end -->
		</div>
		<!-- Menu content end -->
	</nav>
	<!-- Navbar end -->
</template>

<script>

export default {
	methods: {
		// Toogle navbar mode 
		navToggle() {
			let menu = document.querySelector("[role='menu']");
			let isExpanded = menu.getAttribute('aria-expanded');
			menu.setAttribute('aria-expanded', !isExpanded);
			menu.classList.toggle('hidden');
			menu.classList.toggle('flex');
		}
	}
}
</script>

<style></style>