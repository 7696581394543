<template>
	<section class="h-screen px-12 lg:px-32 py-32 flex justify-center items-center">
		<div class="absolute inset-0 overflow-hidden -z-50 flex justify-center items-center sm:p-40 p-10">
			<img src="@/assets/home/home.jpg" alt="img" class="max-w-full max-h-full">
		</div>
		<footer-element></footer-element>
	</section>
</template>

<script>
import FooterElement from '@/components/FooterElement.vue';

export default {
	components: {
		FooterElement,
	}
}
</script>
<style scoped>

</style>