<template>
    <!-- Portfolio section start -->
	<section id="portfolio-section" class="sm:p-40 relative pt-40">
        <!-- Images grid start -->
		<div class="title-text grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            <div class="flex flex-col items-center p-5 sm:p-20">
                <img src="@/assets/work/01.jpg" alt="Image 1" class="w-full aspect-square object-cover">
                <span class="mt-2 text-sm text-gray-600">Casa Fragata | Foto </span>
            </div>
            <div class="flex flex-col items-center p-5 sm:p-20">
                <img src="@/assets/work/02.jpg" alt="Image 2" class="w-full aspect-square object-cover">
                <span class="mt-2 text-sm text-gray-600">Casa Fragata | Maqueta</span>
            </div>
            <div class="flex flex-col items-center p-5 sm:p-20">
                <img src="@/assets/work/03.jpg" alt="Image 3" class="w-full aspect-square object-cover">
                <span class="mt-2 text-sm text-gray-600">Casa Mínima</span>
            </div>
            <div class="flex flex-col items-center p-5 sm:p-20">
                <img src="@/assets/work/04.jpg" alt="Image 4" class="w-full aspect-square object-cover">
                <span class="mt-2 text-sm text-gray-600">Casa Mínima</span>
            </div>
        </div>
		<!-- Images grid end -->
	</section>
</template>

<script>
export default {

}
</script>

<style>

</style>