import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/HomeView.vue'
import AboutSection from '@/sections/AboutSection.vue'
import WorkSection from '@/sections/PortfolioSection.vue'
import PrensaSection from '@/sections/PrensaSection.vue'
import ContactSection from '@/sections/ContactSection.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutSection
  },
  {
    path: '/work',
    name: 'Work',
    component: WorkSection
  },
  {
    path: '/press',
    name: 'Press',
    component: PrensaSection
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactSection
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router