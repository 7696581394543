<template>
	<!-- Footer start -->
	<div class="title-text w-full flex justify-between items-center flex-col md:flex-row gap-8 p-8" style="position: fixed; bottom: 0;">
		<!-- Overview start -->
		<div class="text-black text-center sm:ml-20 sm:text-left">
			<p class="tracking-widest font-semibold text-black"> PSS Arquitectura S.A.C</p>
		</div>
		<!-- Overview end -->

		<!-- Links start -->
		<div class="text-black flex justify-end items-center gap-8 sm:mr-20">
			<a href="https://www.instagram.com/ps_____studio/">
				<i class="fa-brands fa-instagram socialIcon"></i>
			</a>
			<a href="https://www.facebook.com/pedrosanchezstudio">
				<i class="fa-brands fa-facebook socialIcon"></i>
			</a>
			<a href="https://www.linkedin.com/company/pedro-sanchez-studio/?originalSubdomain=pe">
				<i class="fa-brands fa-linkedin socialIcon"></i>
			</a>
			<a href="https://wa.me/51939254245">
				<i class="fa-brands fa-whatsapp socialIcon"></i>
			</a>
		</div>
		<!-- Links end -->
	</div>
	<!-- Footer end -->
</template>

<script>
export default {

}
</script>

<style>

</style>