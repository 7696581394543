<template>
    <!-- Portfolio section start -->
    <section id="portfolio-section" class="px-4 lg:px-8 pt-16 relative" style="padding-top: 0;">
        <div class="press-container title-text grid grid-cols-1 md:grid-cols-2 gap-4 mb-8 sm:p-20 sm:pt-2 pt-40">
            <div class="flex flex-col items-center p-5 sm:p-40">
                <img src="@/assets/press/01.jpg" alt="Image 1" class="h-auto max-w-full object-cover">
                <br>
                <span class="mt-2 text-sm text-gray-600">Revista D+</span>
            </div>
            <div class="flex flex-col items-center p-5 sm:p-40">
                <img src="@/assets/press/02.jpg" alt="Image 2" class="h-auto max-w-full object-cover">
                <br>
                <span class="mt-2 text-sm text-gray-600">Revista ARKINKA</span>
            </div>
        </div>
    </section>
    <!-- Portfolio section end -->
</template>

<script>
export default {

}
</script>

<style>
.press-container {
    padding-bottom: 0px!important;
    margin-bottom: 0px!important;
}
</style>