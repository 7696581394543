<template>
	<div>
		<navbar-element></navbar-element>
		<router-view></router-view>
	</div>

</template>

<script>
import NavbarElement from '@/components/NavbarElement.vue';

export default {
	components: {
		NavbarElement
	}
}
</script>

<style></style>
