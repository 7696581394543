<template>
	<!-- Main section start -->
	<main-section></main-section>
	<!--
	<about-section></about-section>
	<portfolio-section></portfolio-section>
	<contact-section></contact-section>
	-->
	<!-- Main section end -->
</template>

<script>
import MainSection from '@/sections/MainSection.vue';
//import AboutSection from '@/sections/AboutSection.vue';
////import ServiceSection from '@/sections/ServiceSection.vue';
//import PortfolioSection from '@/sections/PortfolioSection.vue';
//import ContactSection from '@/sections/ContactSection.vue';

export default {
	components: {
		MainSection,
		//AboutSection,
		////ServiceSection,
		//PortfolioSection,
		//ContactSection,
	}
}
</script>

<style scoped>


</style>