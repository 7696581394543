<template>
    <!-- Contact section start -->
    <section id="contact-section" class="flex flex-col justify-between min-h-screen pt-40">
        <div class="contact-container flex-grow flex flex-col md:flex-row justify-between items-center px-12 lg:px-32 py-16">
            <div class="w-full md:w-1/2 px-4">
                <form class="flex flex-col items-start p-text">
                    <label for="name" class="text-black text-xs text-left">Nombre</label>
                    <input type="text" name="name" id="name" class="border border-black py-1 px-2 w-full mb-4 text-xs">

                    <label for="user_email" class="text-black text-xs text-left">E-mail</label>
                    <input type="email" name="user_email" id="user_email"
                        class="border border-black py-1 px-2 w-full mb-4 text-xs">

                    <label for="user_message" class="text-black text-xs text-left">Mensaje</label>
                    <textarea name="user_message" id="user_message" rows="5"
                        class="border border-black py-1 px-2 w-full mb-4 text-xs"></textarea>
                    <button class="button-send p-3 text-black">Enviar</button>
                </form>
            </div>
            <div class="w-full md:w-1/2 px-4 md:mt-0 flex flex-col justify-end items-end   ">
                <p class="title-text text-black text-sm mb-6 sm:mt-24">
                    PS STUDIO
                </p>
                <p class="p-text text-black text-sm">
                    Lima, Perú
                </p>

                <p class="p-text text-black text-sm">
                    +51 939 254 245
                </p>
                <p class="p-text text-black text-sm">
                    info@pstudio.pe
                </p>
            </div>
        </div>
        <footer-element class="mt-auto"></footer-element>
    </section>
    <!-- Contact section end -->
</template>

<script>
import FooterElement from '@/components/FooterElement.vue';

export default {
    components: {
        FooterElement,
    }
}
</script>
<style scoped>
.button-send {
    border: 1px solid #3c3c3c;
    background-image: none;
    color: #000000;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
}
@media (min-width: 1024px) {
    .contact-container {
        padding-left: 7rem!important;
        padding-bottom: 20rem!important;
    }
}
@media (max-width: 1024px) {
    .contact-container {
        padding-bottom: 20rem!important;
    }
}
</style>