<template>
    <!-- About section start -->
    <section class="px-4 lg:px-32 py-16 mb-8 flex flex-col lg:flex-row pt-40">
        <div class="lg:w-1/2 flex flex-col justify-between py-10">
            <!-- Main text start -->
            <div>
                <p class="p-text text-stone-500 text-justify">
                    <strong> PS Studio</strong> es un estudio de arquitectura fundado en el 2020 por Pedro Sánchez. (CAP 23109)
                    <br><br>
                    Ubicado en la ciudad de Lima, recibe encargos tanto de clientes locales como internacionales. Tiene
                    obra difundida en distintas revistas de arquitectura y diseño. Difunde artículos de interés en el
                    rubro de construcción.
                </p>
                <p class="p-text text-stone-500 text-justify mt-4">
                    <strong>Pedro Sánchez (1993)</strong> es un arquitecto graduado de la Facultad de Arquitectura de la
                    Universidad Peruana de Ciencias Aplicadas (UPC, 2017) con estudios de pregrado en University of
                    Liverpool, Reino Unido (UOL). Diplomado en Gestión de Proyectos Inmobiliarios UPC (2022). Desde el
                    año 2018 se desempeña como arquitecto independiente, trabajando en conjunto con distintas
                    oficinas de arquitectura en Lima en concursos nacionales e internacionales.
                    <br><br>
                    Se desempeña paralelamente como profesor de taller de diseño en la Facultad de Diseño Profesional de
                    Interiores en la Universidad Peruana de Ciencias Aplicadas (UPC) desde el año 2019.
                </p>
                <p class="p-text mt-6 text-stone-500 text-justify text-sm">
                    <strong>Colaboradores:</strong><br>
                    Arian Lewis <br>
                    Fiorella Jara <br>
                    Leandra Namuche
                </p>
            </div>
            <!-- Main text end -->

            <!-- Social links start -->
            <div class="flex mt-3 gap-4 self-end lg:self-auto">
                <a href="https://www.instagram.com/ps_____studio/">
                    <i class="fa-brands fa-instagram socialIcon"></i>
                </a>
                <a href="https://www.facebook.com/pedrosanchezstudio">
                    <i class="fa-brands fa-facebook socialIcon"></i>
                </a>
                <a href="https://www.linkedin.com/company/pedro-sanchez-studio/?originalSubdomain=pe">
                    <i class="fa-brands fa-linkedin socialIcon"></i>
                </a>
                <a href="https://wa.me/51939254245">
                    <i class="fa-brands fa-whatsapp socialIcon"></i>
                </a>
            </div>
            <!-- Social links end -->
        </div>

        <!-- Photographer image start -->
        <div class="lg:w-1/2 h-64 lg:h-auto overflow-hidden sm:p-10 flex justify-center sm:pl-20">
            <img src="@/assets/image.jpg" alt="img" class="w-full lg:w-3/4 h-full object-cover sm:ml-20">
        </div>
        <!-- Photographer image end -->
    </section>
    <!-- About section end -->
</template>

<script>
export default {

}
</script>

<style></style>